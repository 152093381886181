@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: 'titlePolice'; /* Nom que vous donnerez à votre police */
    src: url('./assets/font/Nunito-SemiBold.ttf') format('truetype');
  
}

.App-header {
  background-color: #34595b;
  display: flex;
  flex-direction: column;
  align-items:stretch;
  justify-content:center;
  font-size: calc(10px + 2vmin);
  color: #e1f1f1;
  padding-bottom: 2em;
  padding-top:2rem;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  background-color: #34595b;
  padding-top: 50px;
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: #e1f1f1;
}

.App-nav {
  position: sticky;
  top: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.cv-container {
  width: 80%;
  @apply mx-auto my-6 px-3 pt-8;
}
.cv-container-nav {
  width: 80%;
  margin: 0 auto;
  @apply px-3 pt-8;
  font-family: "titlePolice";
}
form {
  width: 60%;
  margin-top: 25px;
  padding: 20px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 5px 15px 0; */
  text-align: left;
}

label, input {
  text-align: left;
}
label {
  font-size: 1.1rem;
  @apply mt-4 py-2 px-4;
}
input, textarea, select {
  @apply mt-4 py-2 px-4 bg-cv-white text-cv-canard font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-cv-canard focus:ring-opacity-75;
}
.btn-outline-primary {
  @apply mt-6 py-2 px-4 bg-cv-white text-cv-canard font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-cv-canard focus:ring-opacity-75;
}
@media (max-width: 900px) {  
.cv-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 10px 15px;
}
.display-cloud {
  display:none;
}
.cv-container-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 10px 15px;
}
.cv-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 10px 15px;
}
}
@media (min-width: 900px) {
  .cv-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px 15px 10px 15px;
}
.cv-description {
  width:80%;
  text-align: justify;
  @apply mx-auto my-6 px-3 pt-8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
}
.cv-logo {
  width:100%;
  height: 100px;
  margin: 10px;
  border-radius: 50%;
}

.cv-title {
  font-size: 2rem;
  font-weight:bold;
  font-family: "titlePolice";
  margin-top:10px;
}
.cv-subtitle {
  font-size:1.5rem;
}

.cv-skill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h2 {
  font-size:1.5rem;
  @apply text-cv-canard-light font-bold my-4;
  font-family: "titlePolice";
}
.subtitle {
    font-family: "titlePolice";
}

.btn-primary {
  @apply mt-6 py-4 px-4 bg-cv-canard-light text-white font-semibold rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-cv-canard focus:ring-opacity-75;
}
.btn-outline-primary {
  @apply py-4 px-12 font-semibold rounded-lg shadow-lg focus:outline-none ;
}
.contact {
  margin: 0 auto;
  width:50%;
}

.big {
  font-size: 1.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.buttonfont {
    font-family: "titlePolice";
}

